import React, {useContext, useEffect, useState} from "react";
import AccountImageContainer from "../AccountImageContainer";

import {
  StyledAccountImage,
  StyledActivityAddToFriendsImg,
  StyledActivitySendMessageImg,
  StyledActivitySendTipImg
} from "./styledClientDetails";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import ProfileActionsWindow from "../../../profileActionsWindow/ProfileActionsWindow";
import SendTipWindow from "../../../profileActionsWindow/SendTipWindow";
import ReactHintFactory from "react-hint";
import { useBetween } from "use-between";
import ProfileActionsStates from "../../../profileActionsWindow/ProfileActionsStates";
import http from "../../../../http";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";

const ReactHint = ReactHintFactory(React);

const ClientDetailsMainInfo = ({ nickname }) => {

  const { t } = useTranslation("siteOptions");

  const {
    handleProfileActionsVisible,
    setVisibleTipWindow
  } = useBetween(ProfileActionsStates);

  const [activeNickname, setActiveNickname] = useState(null);
  const [room, setRoom] = useState(null);
  const { authenticated, user } = useContext(AppContext);

  const renderHint = (target) => {
    const { id, nickname, room } = target.dataset;
    setActiveNickname(nickname);
    setRoom(room);

    return <ProfileActionsWindow
      placement="friends"
      keys={+id}
      nickname={nickname}
    />;
  };

  const handleSendTip = () => {
    setActiveNickname(nickname);
    setVisibleTipWindow(true)
  }

  const friends = [
    // { nickname: 'test', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test1', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test2', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test3', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test4', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test5', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test6', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test7', level: '∞', mediaObject: defaultImg },
    // { nickname: 'test8', level: '∞', mediaObject: defaultImg },
  ];

  const friendsCount = 0;

  useEffect(() => {
    // http.get(
    //     "/api/user-friends",
    //     { ...userAuthenticationConfig() }
    // ).then(response => {
    //   console.log('USER FRIENDS')
    //   console.log(response.data)
    // })
    http.post(
        "/api/user-friends",
        {nickname: 'Test7Test'},
        { ...userAuthenticationConfig() }
    ).then(response => {
      console.log('USER FRIENDS')
      console.log(response.data)
    })
  }, []);

  return (
    <StyledAccountImage>
      <ReactHint
        persist
        attribute="data-custom"
        className="custom-hint"
        onRenderContent={renderHint}
        events={{ click: true }}
      />
      {authenticated ? <SendTipWindow
        activeNickname={activeNickname}
        room={room}
      /> : null}
      <div className="user-info">
        <div style={{ position: 'relative' }}>
          <AccountImageContainer nickname={nickname}/>
          <div className="user-info__level" title={t('accountRating')}>
            ∞
          </div>
        </div>

        <div className="user-info__nickname">
          {nickname}
        </div>

        {
          authenticated && user?.nickname !== nickname ?
            <div className={'user-info__footer'}>
              <button className={'add-to-friends'}>
                <StyledActivityAddToFriendsImg/>
              </button>
              <button className={'send-message'}>
                <StyledActivitySendMessageImg/>
              </button>
              {user.roles.includes('ROLE_MANAGER')?<button className={'send-tip'} onClick={handleSendTip}>
                <StyledActivitySendTipImg/>
              </button>:null}
            </div> :
            null
        }
      </div>
      <div className={'user-friends'}>
        <div className={'user-friends__title'}>
          <b>{friendsCount}</b> {t('friends')}
        </div>
        <div className={'user-friends__list' + (friendsCount === 0 ? ' empty' : '')}>
          {friends.length > 0 ? friends.map((friend, key) => (
            <div
              title={friend.nickname}
              className={'user-friends__list__item'}
              key={key}
              onClick={() => handleProfileActionsVisible(key, 'friends')}
              data-custom={true}
              data-custom-at="right"
              data-id={key}
              data-nickname={friend.nickname}
            >
              <img src={friend.mediaObject} alt={friend.nickname}/>
              <div className={'user-friends__list__item__level'}>{friend.level}</div>
            </div>
          )) : t('nobodysHere')}
        </div>
      </div>
    </StyledAccountImage>
  );
};

export default ClientDetailsMainInfo;